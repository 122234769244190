<template>
    <v-row justify="center" class="py-5">
        <v-col cols="12" sm="6">
            <v-form @submit.prevent="search" onSubmit="return false;" @keyup.enter.native.prevent="search">
            <p class="text-center">
                Search for an account name, domain, or DNS record:
            </p>
            <v-text-field v-model="searchQuery" ref="searchInput" dense solo color="purple darken-2" hint="Search for an account name, domain, or DNS record" placeholder="Search...">
                <template #prepend-inner>
                    <font-awesome-icon :icon="['fas', 'search']" fixed-width/> <!-- style="font-size: 20px;" -->
                </template>
            </v-text-field>
            <v-row no-gutters justify="center">
            <v-btn @click="search" elevation="4" color="purple darken-2 white--text" class="mt-4">Search</v-btn>
            </v-row>
            </v-form>
            <p class="mb-15"></p>
            <v-alert v-if="notFound">
                No results for your query.
            </v-alert>
            <!-- TODO: search results... -->
            <template v-if="resultAccountList.length > 0">
                <p class="text-overline mb-0 mt-2">Accounts</p>
                <v-list dense>
                    <v-list-item v-for="item in resultAccountList" :key="item.id" @click="onClickAccount(item)">
                        <v-list-item-content>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            <!-- <BrandList :list="resultList" @selected="onSelectBrand"/> -->
                <!-- {{ JSON.stringify(resultList) }} -->
            </template>
            <template v-if="resultDomainList.length > 0">
                <p class="text-overline mb-0 mt-2">Domains</p>
                <v-list dense>
                    <v-list-item v-for="item in resultDomainList" :key="item.id" @click="onClickDomain(item)">
                        <v-list-item-content>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            <!-- <BrandList :list="resultList" @selected="onSelectBrand"/> -->
                <!-- {{ JSON.stringify(resultList) }} -->
            </template>
            <template v-if="resultDnsRecordList.length > 0">
                <p class="text-overline mb-0 mt-2">DNS Records</p>
                <v-list dense>
                    <v-list-item v-for="item in resultDnsRecordList" :key="item.id" @click="onClickDnsRecord(item)">
                        <v-list-item-content>
                            <v-list-item-title>{{ item.domain }} {{ item.type }} {{ item.key }} {{ item.value }} {{ item.ttl }} {{ item.readonly }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            <!-- <BrandList :list="resultList" @selected="onSelectBrand"/> -->
                <!-- {{ JSON.stringify(resultList) }} -->
            </template>
            <!-- TODO: + button for new account, new domain, or new record? if it's new domain or new record we'd also need to ask them which account they want to add it to ... -->
            <!-- <p class="mt-12"><a :href="mainWebsiteURL">Create a brand profile</a></p> -->
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
import { mapState } from 'vuex';
// import BrandList from '@/components/BrandList.vue';

export default {
    components: {
        // BrandList,
    },
    data: () => ({
        searchQuery: '',
        searchTimestamp: null,
        notFound: false,
        notFoundTimeout: null,
        resultAccountList: [],
        resultDomainList: [],
        resultDnsRecordList: [],
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        // mainWebsiteURL() {
        //     return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://brandprofile.org';
        // },
    },
    methods: {
        reset() {
            this.notFound = false;
            if (this.notFoundTimeout) {
                clearTimeout(this.notFoundTimeout);
                this.notFoundTimeout = null;
            }
            this.resultAccountList = [];
            this.resultDomainList = [];
            this.resultDnsRecordList = [];
        },
        async search() {
            if (Number.isInteger(this.searchTimestamp) && this.searchTimestamp + 500 > Date.now()) {
                return;
            }
            this.searchTimestamp = Date.now();
            try {
                if (this.$route.query.q !== this.searchQuery) {
                    // update URL in address bar
                    this.$router.replace({ name: 'search', query: { q: this.searchQuery } });
                }
                this.reset();
                this.$store.commit('loading', { search: true });
                const response = await this.$client.user(this.user.id).user.search({ q: this.searchQuery });
                // TODO: also search domains (in all user's accounts) and dns records (in all user's accounts) -- this would probably be better on the server,  so it can query user's accounts, do a parallel search, collate the results, remove duplicates, etc.
                console.log(`Search.vue: response ${JSON.stringify(response)}`);
                if (Array.isArray(response.account_list)) {
                    this.resultAccountList = response.account_list;
                }
                if (Array.isArray(response.domain_list)) {
                    this.resultDomainList = response.domain_list;
                }
                if (Array.isArray(response.dns_record_list)) {
                    this.resultDnsRecordList = response.dns_record_list;
                }
                if (this.resultAccountList.length === 0 && this.resultDomainList.length === 0 && this.resultDnsRecordList.length === 0) {
                    this.notFound = true;
                    this.notFoundTimeout = setTimeout(() => { this.notFound = false; }, 15000); // clear message in 15 seconds
                }
            } catch (err) {
                console.error('failed to search', err);
                // this.error = true;
            } finally {
                this.$store.commit('loading', { search: false });
            }
        },
        // onSelectBrand({ alias }) {
        //     if (this.$route.query.intent === 'unsubscribe') {
        //         this.$router.push({ name: 'brand-view-profile', params: { alias }, hash: '#unsubscribe' });
        //     } else {
        //         this.$router.push({ name: 'brand-view-profile', params: { alias } });
        //     }
        // },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        onClickAccount(item) {
            this.$router.push({ name: 'account-dashboard', params: { accountId: item.id } });
        },
        onClickDomain(item) {
            this.$router.push({ name: 'account-view-domain', params: { accountId: item.account_id, domain: item.name } });
        },
        onClickDnsRecord(item) {
            this.$router.push({ name: 'account-edit-dns', params: { accountId: item.account_id, domain: item.domain }, query: { type: item.type, key: item.key, value: item.value } });
        },
    },
    created() {
        // listen for search events coming from any component (but specifically the navbar search button)
        this.$bus.$on('search', (q) => {
            this.searchQuery = q;
            this.$nextTick(() => {
                setTimeout(() => { this.activate('searchInput'); }, 1);
            });
            if (q) {
                this.search();
            } else {
                this.reset();
            }
        });
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => { this.activate('searchInput'); }, 1);
        });
        this.searchQuery = this.$route.query.q ?? '';
        if (this.searchQuery) {
            this.search();
        }
    },
};
</script>
